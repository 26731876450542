export default {
  data() {
    return {
      show: false,
      inputValue: "",
      captchasInfo: {},
      phoneOrEmail: "",
      IsPhoneValid: true
    };
  },
  methods: {
    loadCapts() {
      this.$axios.getCaptchas().then(res => {
        if (res.code === 1) {
          this.captchasInfo = res.data;
        }
      });
    },
    showDialog(phoneOrEmail, isPhone) {
      this.IsPhoneValid = isPhone;
      this.phoneOrEmail = phoneOrEmail;
      this.show = true;
      this.loadCapts();
    },
    onConfirm() {
      if (!this.inputValue) {
        this.$notify({
          message: "请输入图片验证码",
          type: "danger"
        });
        return;
      }
      console.log("IsPhoneValid: ", this.IsPhoneValid);
      if (this.IsPhoneValid) {
        this.$axios.sendSmsCode({
          mobile_number: this.phoneOrEmail,
          check_email_unique: 2,
          captcha_key: this.captchasInfo.captcha_key,
          captcha_code: this.inputValue
        }).then(res => {
          if (res.code === 1) {
            this.show = false;
            this.inputValue = "";
            this.captchasInfo = {};
            this.$notify({
              message: "验证码已发送，请注意查收短信！",
              type: "success"
            });
            this.$emit("onConfirm", res.data.key);
          }
        }).catch(() => {
          this.inputValue = "";
          this.loadCapts();
        });
      } else {
        this.$axios.registerSendEmailCode({
          email: this.phoneOrEmail,
          captcha_key: this.captchasInfo.captcha_key,
          captcha_code: this.inputValue,
          type: 1
        }).then(res => {
          if (res.code === 1) {
            this.show = false;
            this.inputValue = "";
            this.captchasInfo = {};
            this.$notify({
              message: "验证码已发送，请注意查收邮箱！",
              type: "success"
            });
            this.$emit("onConfirm", res.data.key);
          }
        }).catch(() => {
          this.inputValue = "";
          this.loadCapts();
        });
      }
    },
    handleBeforeClose(action, done) {
      console.log(action, done);
      if (action === "confirm") {
        // 当操作是确认操作时，阻止自动关闭
        console.log("阻止默认的关闭行为");
      } else {
        // 对于取消操作，还是允许默认关闭行为
        this.show = false;
        this.inputValue = "";
        this.captchasInfo = {};
      }
    }
  }
};