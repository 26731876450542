import { ref, onMounted, toRaw } from 'vue';
import globalCountryList from "@/util/globalCountryList.js";
export default {
  emits: ['close', "confirm"],
  setup(props, {
    emit
  }) {
    const areaName = ref("");
    const allCountrys = globalCountryList;
    const filterCountry = ref([]);
    onMounted(() => {
      filterCountry.value = allCountrys;
    });
    const goBack = () => {
      emit("close");
    };
    const onSearch = () => {
      filterCountry.value = allCountrys.filter(country => {
        return country.areaName.includes(areaName.value);
      });
    };
    const cellClick = item => {
      emit("confirm", toRaw(item));
    };
    return {
      areaName,
      filterCountry,
      goBack,
      onSearch,
      cellClick
    };
  }
};