import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-f165438a"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "margin": "20px auto",
    "width": "90%"
  }
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_loading = _resolveComponent("van-loading");
  const _component_van_image = _resolveComponent("van-image");
  const _component_van_field = _resolveComponent("van-field");
  const _component_van_dialog = _resolveComponent("van-dialog");
  return _openBlock(), _createBlock(_component_van_dialog, {
    show: $data.show,
    "onUpdate:show": _cache[1] || (_cache[1] = $event => $data.show = $event),
    "show-cancel-button": "",
    "before-close": $options.handleBeforeClose,
    onConfirm: $options.onConfirm
  }, {
    default: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_image, {
      src: $data.captchasInfo.captcha_image_content,
      width: "67vw",
      height: "25vw",
      fit: "none",
      position: "center",
      onClick: $options.loadCapts
    }, {
      loading: _withCtx(() => [_createVNode(_component_van_loading, {
        type: "spinner",
        size: "20"
      })]),
      _: 1
    }, 8, ["src", "onClick"]), _createVNode(_component_van_field, {
      "input-align": "center",
      class: "codeInput",
      modelValue: $data.inputValue,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.inputValue = $event),
      placeholder: "请输入图片验证码"
    }, null, 8, ["modelValue"])])]),
    _: 1
  }, 8, ["show", "before-close", "onConfirm"]);
}