let globalCountryList = [
  {
    areaName: "中国大陆",
    checkKey: "^(86){0,1}1\\d{10}$",
    code: "86",
    domainAbbreviation: "CN",
  },
  {
    areaName: "中国香港",
    checkKey: "^(00){0,1}(852){1}0{0,1}[1,4,5,6,8,9](?:\\d{7}|\\d{8}|\\d{12})$",
    code: "852",
    domainAbbreviation: "HK",
  },
  {
    areaName: "中国澳门",
    checkKey: "^(00){0,1}(853){1}6\\d{7}$",
    code: "853",
    domainAbbreviation: "MO",
  },
  {
    areaName: "中国台湾",
    checkKey: "^(00){0,1}(886){1}0{0,1}[6,7,9](?:\\d{7}|\\d{8}|\\d{10})$",
    code: "886",
    domainAbbreviation: "TW",
  },
  {
    areaName: "韩国",
    checkKey: "^(00){0,1}(82){1}0{0,1}[7,1](?:\\d{8}|\\d{9})$",
    code: "82",
    domainAbbreviation: "KR",
  },
  {
    areaName: "日本",
    checkKey: "^(00){0,1}(81){1}0{0,1}[7,8,9](?:\\d{8}|\\d{9})$",
    code: "81",
    domainAbbreviation: "JP",
  },
  {
    areaName: "澳大利亚",
    checkKey: "^(00){0,1}(61){1}4\\d{8,9}$",
    code: "61",
    domainAbbreviation: "AU",
  },
  {
    areaName: "新加坡",
    checkKey: "^(00){0,1}(65){1}[13689]\\d{6,7}$",
    code: "65",
    domainAbbreviation: "SG",
  },
  {
    areaName: "马来西亚",
    checkKey: "^(00){0,1}(60){1}1\\d{8,9}$",
    code: "60",
    domainAbbreviation: "MY",
  },
  {
    areaName: "泰国",
    checkKey: "^(00){0,1}(66){1}[13456789]\\d{7,8}$",
    code: "66",
    domainAbbreviation: "TH",
  },
  {
    areaName: "越南",
    checkKey: "^(00){0,1}(84){1}[1-9]\\d{6,9}$",
    code: "84",
    domainAbbreviation: "VN",
  },
  {
    areaName: "菲律宾",
    checkKey: "^(00){0,1}(63){1}[24579](\\d{7,9}|\\d{12})$",
    code: "63",
    domainAbbreviation: "PH",
  },
  {
    areaName: "印度尼西亚",
    checkKey: "^(00){0,1}(62){1}[2-9]\\d{7,11}$",
    code: "62",
    domainAbbreviation: "ID",
  },
  {
    areaName: "德国",
    checkKey: "^(00){0,1}(49){1}1(\\d{5,6}|\\d{9,12})$",
    code: "49",
    domainAbbreviation: "DE",
  },
  {
    areaName: "意大利",
    checkKey: "^(00){0,1}(39){1}[37]\\d{8,11}$",
    code: "39",
    domainAbbreviation: "IT",
  },
  {
    areaName: "法国",
    checkKey: "^(00){0,1}(33){1}(\\d{6}|\\d{8,10})$",
    code: "33",
    domainAbbreviation: "FR",
  },
  {
    areaName: "俄罗斯",
    checkKey: "^(00){0,1}(7){1}[13489]\\d{9,11}$",
    code: "7",
    domainAbbreviation: "RU",
  },
  {
    areaName: "新西兰",
    checkKey: "^(00){0,1}(64){1}[278]\\d{7,9}$",
    code: "64",
    domainAbbreviation: "NZ",
  },
  {
    areaName: "荷兰",
    checkKey: "^(00){0,1}(31){1}6\\d{8}$",
    code: "31",
    domainAbbreviation: "NL",
  },
  {
    areaName: "瑞典",
    checkKey: "^(00){0,1}(46){1}[124-7](\\d{8}|\\d{10}|\\d{12})$",
    code: "46",
    domainAbbreviation: "SE",
  },
  {
    areaName: "乌克兰",
    checkKey: "^(00){0,1}(380){1}[3-79]\\d{8,9}$",
    code: "380",
    domainAbbreviation: "UA",
  },
  {
    areaName: "阿联酋",
    checkKey: "^(00){0,1}(971){1}\\d{6,12}$",
    code: "971",
    domainAbbreviation: "AE",
  },
  {
    areaName: "阿根廷",
    checkKey: "^(00){0,1}(54){1}\\d{6,12}$",
    code: "54",
    domainAbbreviation: "AR",
  },
  {
    areaName: "奥地利",
    checkKey: "^(00){0,1}(43){1}\\d{6,12}$",
    code: "43",
    domainAbbreviation: "AT",
  },
  {
    areaName: "比利时",
    checkKey: "^(00){0,1}(32){1}\\d{6,12}$",
    code: "32",
    domainAbbreviation: "BE",
  },
  {
    areaName: "保加利亚",
    checkKey: "^(00){0,1}(359){1}\\d{6,12}$",
    code: "359",
    domainAbbreviation: "BG",
  },
  {
    areaName: "巴西",
    checkKey: "^(00){0,1}(55){1}\\d{6,12}$",
    code: "55",
    domainAbbreviation: "BR",
  },
  {
    areaName: "巴哈马",
    checkKey: "^(00){0,1}(1242){1}\\d{6,12}$",
    code: "1242",
    domainAbbreviation: "BS",
  },
  {
    areaName: "白俄罗斯",
    checkKey: "^(00){0,1}(375){1}\\d{6,12}$",
    code: "375",
    domainAbbreviation: "BY",
  },
  {
    areaName: "伯利兹",
    checkKey: "^(00){0,1}(501){1}\\d{6,12}$",
    code: "501",
    domainAbbreviation: "BZ",
  },
  {
    areaName: "瑞士",
    checkKey: "^(00){0,1}(41){1}\\d{6,12}$",
    code: "41",
    domainAbbreviation: "CH",
  },
  {
    areaName: "智利",
    checkKey: "^(00){0,1}(56){1}\\d{6,12}$",
    code: "56",
    domainAbbreviation: "CL",
  },
  {
    areaName: "哥伦比亚",
    checkKey: "^(00){0,1}(57){1}\\d{6,12}$",
    code: "57",
    domainAbbreviation: "CO",
  },
  {
    areaName: "丹麦",
    checkKey: "^(00){0,1}(45){1}\\d{6,12}$",
    code: "45",
    domainAbbreviation: "DK",
  },
  {
    areaName: "爱沙尼亚",
    checkKey: "^(00){0,1}(372){1}\\d{6,12}$",
    code: "372",
    domainAbbreviation: "EE",
  },
  {
    areaName: "埃及",
    checkKey: "^(00){0,1}(20){1}\\d{6,12}$",
    code: "20",
    domainAbbreviation: "EG",
  },
  {
    areaName: "西班牙",
    checkKey: "^(00){0,1}(34){1}\\d{6,12}$",
    code: "34",
    domainAbbreviation: "ES",
  },
  {
    areaName: "芬兰",
    checkKey: "^(00){0,1}(358){1}\\d{6,12}$",
    code: "358",
    domainAbbreviation: "FI",
  },
  {
    areaName: "匈牙利",
    checkKey: "^(00){0,1}(36){1}\\d{6,12}$",
    code: "36",
    domainAbbreviation: "HU",
  },
  {
    areaName: "希腊",
    checkKey: "^(00){0,1}(30){1}\\d{6,12}$",
    code: "30",
    domainAbbreviation: "GR",
  },
  {
    areaName: "爱尔兰",
    checkKey: "^(00){0,1}(353){1}\\d{6,12}$",
    code: "353",
    domainAbbreviation: "IE",
  },
  {
    areaName: "以色列",
    checkKey: "^(00){0,1}(972){1}\\d{6,12}$",
    code: "972",
    domainAbbreviation: "IL",
  },
  {
    areaName: "印度",
    checkKey: "^(00){0,1}(91){1}\\d{6,12}$",
    code: "91",
    domainAbbreviation: "IN",
  },
  {
    areaName: "约旦",
    checkKey: "^(00){0,1}(962){1}\\d{6,12}$",
    code: "962",
    domainAbbreviation: "JO",
  },
  {
    areaName: "吉尔吉斯斯坦",
    checkKey: "^(00){0,1}(996){1}\\d{6,12}$",
    code: "996",
    domainAbbreviation: "KG",
  },
  {
    areaName: "柬埔寨",
    checkKey: "^(00){0,1}(855){1}\\d{6,12}$",
    code: "855",
    domainAbbreviation: "KH",
  },
  {
    areaName: "斯里兰卡",
    checkKey: "^(00){0,1}(94){1}\\d{6,12}$",
    code: "94",
    domainAbbreviation: "LK",
  },
  {
    areaName: "立陶宛",
    checkKey: "^(00){0,1}(370){1}\\d{6,12}$",
    code: "370",
    domainAbbreviation: "LT",
  },
  {
    areaName: "卢森堡",
    checkKey: "^(00){0,1}(352){1}\\d{6,12}$",
    code: "352",
    domainAbbreviation: "LU",
  },
  {
    areaName: "摩洛哥",
    checkKey: "^(00){0,1}(212){1}\\d{6,12}$",
    code: "212",
    domainAbbreviation: "MA",
  },
  {
    areaName: "蒙古",
    checkKey: "^(00){0,1}(976){1}\\d{6,12}$",
    code: "976",
    domainAbbreviation: "MN",
  },
  {
    areaName: "马尔代夫",
    checkKey: "^(00){0,1}(960){1}\\d{6,12}$",
    code: "960",
    domainAbbreviation: "MV",
  },
  {
    areaName: "尼日利亚",
    checkKey: "^(00){0,1}(234){1}\\d{6,12}$",
    code: "234",
    domainAbbreviation: "NG",
  },
  {
    areaName: "墨西哥",
    checkKey: "^(00){0,1}(52){1}\\d{6,12}$",
    code: "52",
    domainAbbreviation: "MX",
  },
  {
    areaName: "挪威",
    checkKey: "^(00){0,1}(47){1}\\d{6,12}$",
    code: "47",
    domainAbbreviation: "NO",
  },
  {
    areaName: "巴拿马",
    checkKey: "^(00){0,1}(507){1}\\d{6,12}$",
    code: "507",
    domainAbbreviation: "PA",
  },
  {
    areaName: "秘鲁",
    checkKey: "^(00){0,1}(51){1}\\d{6,12}$",
    code: "51",
    domainAbbreviation: "PE",
  },
  {
    areaName: "波兰",
    checkKey: "^(00){0,1}(48){1}\\d{6,12}$",
    code: "48",
    domainAbbreviation: "PL",
  },
  {
    areaName: "葡萄牙",
    checkKey: "^(00){0,1}(351){1}\\d{6,12}$",
    code: "351",
    domainAbbreviation: "PT",
  },
  {
    areaName: "卡塔尔",
    checkKey: "^(00){0,1}(974){1}\\d{6,12}$",
    code: "974",
    domainAbbreviation: "QA",
  },
  {
    areaName: "罗马尼亚",
    checkKey: "^(00){0,1}(40){1}\\d{6,12}$",
    code: "40",
    domainAbbreviation: "RO",
  },
  {
    areaName: "塞尔维亚",
    checkKey: "^(00){0,1}(381){1}\\d{6,12}$",
    code: "381",
    domainAbbreviation: "RS",
  },
  {
    areaName: "塞舌尔",
    checkKey: "^(00){0,1}(248){1}\\d{6,12}$",
    code: "248",
    domainAbbreviation: "SC",
  },
  {
    areaName: "沙特阿拉伯",
    checkKey: "^(00){0,1}(966){1}\\d{6,12}$",
    code: "966",
    domainAbbreviation: "SA",
  },
  {
    areaName: "突尼斯",
    checkKey: "^(00){0,1}(216){1}\\d{6,12}$",
    code: "216",
    domainAbbreviation: "TN",
  },
  {
    areaName: "土耳其",
    checkKey: "^(00){0,1}(90){1}\\d{6,12}$",
    code: "90",
    domainAbbreviation: "TR",
  },
  {
    areaName: "委内瑞拉",
    checkKey: "^(00){0,1}(58){1}\\d{6,12}$",
    code: "58",
    domainAbbreviation: "VE",
  },
  {
    areaName: "英属维尔京群岛",
    checkKey: "^(00){0,1}(1284){1}\\d{6,12}$",
    code: "1284",
    domainAbbreviation: "VG",
  },
  {
    areaName: "南非",
    checkKey: "^(00){0,1}(27){1}\\d{6,12}$",
    code: "27",
    domainAbbreviation: "ZA",
  },
  {
    areaName: "阿尔巴尼亚",
    checkKey: "^(00){0,1}(355){1}\\d{6,15}$",
    code: "355",
    domainAbbreviation: "AL",
  },
  {
    areaName: "安哥拉",
    checkKey: "^(00){0,1}(244){1}\\d{6,15}$",
    code: "244",
    domainAbbreviation: "AO",
  },
  {
    areaName: "阿塞拜疆",
    checkKey: "^(00){0,1}(994){1}\\d{6,15}$",
    code: "994",
    domainAbbreviation: "AZ",
  },
  {
    areaName: "布基纳法索",
    checkKey: "^(00){0,1}(226){1}\\d{6,15}$",
    code: "226",
    domainAbbreviation: "BF",
  },
  {
    areaName: "巴林",
    checkKey: "^(00){0,1}(973){1}\\d{6,15}$",
    code: "973",
    domainAbbreviation: "BH",
  },
  {
    areaName: "贝宁",
    checkKey: "^(00){0,1}(229){1}\\d{6,15}$",
    code: "229",
    domainAbbreviation: "BJ",
  },
  {
    areaName: "文莱",
    checkKey: "^(00){0,1}(673){1}\\d{6,15}$",
    code: "673",
    domainAbbreviation: "BN",
  },
  {
    areaName: "玻利维亚",
    checkKey: "^(00){0,1}(591){1}\\d{6,15}$",
    code: "591",
    domainAbbreviation: "BO",
  },
  {
    areaName: "喀麦隆",
    checkKey: "^(00){0,1}(237){1}\\d{6,15}$",
    code: "237",
    domainAbbreviation: "CM",
  },
  {
    areaName: "哥斯达黎加",
    checkKey: "^(00){0,1}(506){1}\\d{6,15}$",
    code: "506",
    domainAbbreviation: "CR",
  },
  {
    areaName: "佛得角",
    checkKey: "^(00){0,1}(238){1}\\d{6,15}$",
    code: "238",
    domainAbbreviation: "CV",
  },
  {
    areaName: "塞浦路斯",
    checkKey: "^(00){0,1}(357){1}\\d{6,15}$",
    code: "357",
    domainAbbreviation: "CY",
  },
  {
    areaName: "吉布提",
    checkKey: "^(00){0,1}(253){1}\\d{6,15}$",
    code: "253",
    domainAbbreviation: "DJ",
  },
  {
    areaName: "加蓬",
    checkKey: "^(00){0,1}(241){1}\\d{6,15}$",
    code: "241",
    domainAbbreviation: "GA",
  },
  {
    areaName: "格林纳达",
    checkKey: "^(00){0,1}(1473){1}\\d{6,15}$",
    code: "1473",
    domainAbbreviation: "GD",
  },
  {
    areaName: "格鲁吉亚",
    checkKey: "^(00){0,1}(995){1}\\d{6,15}$",
    code: "995",
    domainAbbreviation: "GE",
  },
  {
    areaName: "冈比亚",
    checkKey: "^(00){0,1}(220){1}\\d{6,15}$",
    code: "220",
    domainAbbreviation: "GM",
  },
  {
    areaName: "几内亚",
    checkKey: "^(00){0,1}(224){1}\\d{6,15}$",
    code: "224",
    domainAbbreviation: "GN",
  },
  {
    areaName: "赤道几内亚",
    checkKey: "^(00){0,1}(240){1}\\d{6,15}$",
    code: "240",
    domainAbbreviation: "GQ",
  },
  {
    areaName: "危地马拉",
    checkKey: "^(00){0,1}(502){1}\\d{6,15}$",
    code: "502",
    domainAbbreviation: "GT",
  },
  {
    areaName: "几内亚比绍",
    checkKey: "^(00){0,1}(245){1}\\d{6,15}$",
    code: "245",
    domainAbbreviation: "GW",
  },
  {
    areaName: "圭亚那",
    checkKey: "^(00){0,1}(592){1}\\d{6,15}$",
    code: "592",
    domainAbbreviation: "GY",
  },
  {
    areaName: "洪都拉斯",
    checkKey: "^(00){0,1}(504){1}\\d{6,15}$",
    code: "504",
    domainAbbreviation: "HN",
  },
  {
    areaName: "克罗地亚",
    checkKey: "^(00){0,1}(385){1}\\d{6,15}$",
    code: "385",
    domainAbbreviation: "HR",
  },
  {
    areaName: "牙买加",
    checkKey: "^(00){0,1}(1876){1}\\d{6,15}$",
    code: "1876",
    domainAbbreviation: "JM",
  },
  {
    areaName: "肯尼亚",
    checkKey: "^(00){0,1}(254){1}\\d{6,15}$",
    code: "254",
    domainAbbreviation: "KE",
  },
  {
    areaName: "科摩罗",
    checkKey: "^(00){0,1}(269){1}\\d{6,15}$",
    code: "269",
    domainAbbreviation: "KM",
  },
  {
    areaName: "科威特",
    checkKey: "^(00){0,1}(965){1}\\d{6,15}$",
    code: "965",
    domainAbbreviation: "KW",
  },
  {
    areaName: "开曼群岛",
    checkKey: "^(00){0,1}(1345){1}\\d{6,15}$",
    code: "1345",
    domainAbbreviation: "KY",
  },
  {
    areaName: "莱索托",
    checkKey: "^(00){0,1}(266){1}\\d{6,15}$",
    code: "266",
    domainAbbreviation: "LS",
  },
  {
    areaName: "拉脱维亚",
    checkKey: "^(00){0,1}(371){1}\\d{6,15}$",
    code: "371",
    domainAbbreviation: "LV",
  },
  {
    areaName: "摩尔多瓦",
    checkKey: "^(00){0,1}(373){1}\\d{6,15}$",
    code: "373",
    domainAbbreviation: "MD",
  },
  {
    areaName: "马达加斯加",
    checkKey: "^(00){0,1}(261){1}\\d{6,15}$",
    code: "261",
    domainAbbreviation: "MG",
  },
  {
    areaName: "马里",
    checkKey: "^(00){0,1}(223){1}\\d{6,15}$",
    code: "223",
    domainAbbreviation: "ML",
  },
  {
    areaName: "毛里塔尼亚",
    checkKey: "^(00){0,1}(222){1}\\d{6,15}$",
    code: "222",
    domainAbbreviation: "MR",
  },
  {
    areaName: "毛里求斯",
    checkKey: "^(00){0,1}(230){1}\\d{6,15}$",
    code: "230",
    domainAbbreviation: "MU",
  },
  {
    areaName: "马拉维",
    checkKey: "^(00){0,1}(265){1}\\d{6,15}$",
    code: "265",
    domainAbbreviation: "MW",
  },
  {
    areaName: "莫桑比克",
    checkKey: "^(00){0,1}(258){1}\\d{6,15}$",
    code: "258",
    domainAbbreviation: "MZ",
  },
  {
    areaName: "纳米比亚",
    checkKey: "^(00){0,1}(264){1}\\d{6,15}$",
    code: "264",
    domainAbbreviation: "NA",
  },
  {
    areaName: "尼日尔",
    checkKey: "^(00){0,1}(227){1}\\d{6,15}$",
    code: "227",
    domainAbbreviation: "NE",
  },
  {
    areaName: "尼加拉瓜",
    checkKey: "^(00){0,1}(505){1}\\d{6,15}$",
    code: "505",
    domainAbbreviation: "NI",
  },
  {
    areaName: "阿曼",
    checkKey: "^(00){0,1}(968){1}\\d{6,15}$",
    code: "968",
    domainAbbreviation: "OM",
  },
  {
    areaName: "巴布亚新几内亚",
    checkKey: "^(00){0,1}(675){1}\\d{6,15}$",
    code: "675",
    domainAbbreviation: "PG",
  },
  {
    areaName: "巴勒斯坦",
    checkKey: "^(00){0,1}(970){1}\\d{6,15}$",
    code: "970",
    domainAbbreviation: "PS",
  },
  {
    areaName: "卢旺达",
    checkKey: "^(00){0,1}(250){1}\\d{6,15}$",
    code: "250",
    domainAbbreviation: "RW",
  },
  {
    areaName: "斯洛文尼亚",
    checkKey: "^(00){0,1}(386){1}\\d{6,15}$",
    code: "386",
    domainAbbreviation: "SI",
  },
  {
    areaName: "斯洛伐克",
    checkKey: "^(00){0,1}(421){1}\\d{6,15}$",
    code: "421",
    domainAbbreviation: "SK",
  },
  {
    areaName: "塞拉利昂",
    checkKey: "^(00){0,1}(232){1}\\d{6,15}$",
    code: "232",
    domainAbbreviation: "SL",
  },
  {
    areaName: "塞内加尔",
    checkKey: "^(00){0,1}(221){1}\\d{6,15}$",
    code: "221",
    domainAbbreviation: "SN",
  },
  {
    areaName: "苏里南",
    checkKey: "^(00){0,1}(597){1}\\d{6,15}$",
    code: "597",
    domainAbbreviation: "SR",
  },
  {
    areaName: "萨尔瓦多",
    checkKey: "^(00){0,1}(503){1}\\d{6,15}$",
    code: "503",
    domainAbbreviation: "SV",
  },
  {
    areaName: "斯威士兰",
    checkKey: "^(00){0,1}(268){1}\\d{6,15}$",
    code: "268",
    domainAbbreviation: "SZ",
  },
  {
    areaName: "乍得",
    checkKey: "^(00){0,1}(235){1}\\d{6,15}$",
    code: "235",
    domainAbbreviation: "TD",
  },
  {
    areaName: "多哥",
    checkKey: "^(00){0,1}(228){1}\\d{6,15}$",
    code: "228",
    domainAbbreviation: "TG",
  },
  {
    areaName: "塔吉克斯坦",
    checkKey: "^(00){0,1}(992){1}\\d{6,15}$",
    code: "992",
    domainAbbreviation: "TJ",
  },
  {
    areaName: "土库曼斯坦",
    checkKey: "^(00){0,1}(993){1}\\d{6,15}$",
    code: "993",
    domainAbbreviation: "TM",
  },
  {
    areaName: "特立尼达和多巴哥",
    checkKey: "^(00){0,1}(1868){1}\\d{6,15}$",
    code: "1868",
    domainAbbreviation: "TT",
  },
  {
    areaName: "坦桑尼亚",
    checkKey: "^(00){0,1}(255){1}\\d{6,15}$",
    code: "255",
    domainAbbreviation: "TZ",
  },
  {
    areaName: "乌干达",
    checkKey: "^(00){0,1}(256){1}\\d{6,15}$",
    code: "256",
    domainAbbreviation: "UG",
  },
  {
    areaName: "乌拉圭",
    checkKey: "^(00){0,1}(598){1}\\d{6,15}$",
    code: "598",
    domainAbbreviation: "UY",
  },
  {
    areaName: "乌兹别克斯坦",
    checkKey: "^(00){0,1}(998){1}\\d{6,15}$",
    code: "998",
    domainAbbreviation: "UZ",
  },
  {
    areaName: "也门",
    checkKey: "^(00){0,1}(967){1}\\d{6,15}$",
    code: "967",
    domainAbbreviation: "YE",
  },
  {
    areaName: "赞比亚",
    checkKey: "^(00){0,1}(260){1}\\d{6,15}$",
    code: "260",
    domainAbbreviation: "ZM",
  },
  {
    areaName: "津巴布韦",
    checkKey: "^(00){0,1}(263){1}\\d{6,15}$",
    code: "263",
    domainAbbreviation: "ZW",
  },
  {
    areaName: "安道尔共和国",
    checkKey: "^(00){0,1}(376){1}\\d{6,15}$",
    code: "376",
    domainAbbreviation: "AD",
  },
  {
    areaName: "阿富汗",
    checkKey: "^(00){0,1}(93){1}\\d{6,15}$",
    code: "93",
    domainAbbreviation: "AF",
  },
  {
    areaName: "亚美尼亚",
    checkKey: "^(00){0,1}(374){1}\\d{6,15}$",
    code: "374",
    domainAbbreviation: "AM",
  },
  {
    areaName: "波斯尼亚和黑塞哥维那",
    checkKey: "^(00){0,1}(387){1}\\d{6,15}$",
    code: "387",
    domainAbbreviation: "BA",
  },
  {
    areaName: "孟加拉国",
    checkKey: "^(00){0,1}(880){1}\\d{6,15}$",
    code: "880",
    domainAbbreviation: "BD",
  },
  {
    areaName: "布隆迪",
    checkKey: "^(00){0,1}(257){1}\\d{6,15}$",
    code: "257",
    domainAbbreviation: "BI",
  },
  {
    areaName: "博茨瓦纳",
    checkKey: "^(00){0,1}(267){1}\\d{6,15}$",
    code: "267",
    domainAbbreviation: "BW",
  },
  {
    areaName: "刚果(金)",
    checkKey: "^(00){0,1}(243){1}\\d{6,15}$",
    code: "243",
    domainAbbreviation: "CD",
  },
  {
    areaName: "中非",
    checkKey: "^(00){0,1}(236){1}\\d{6,15}$",
    code: "236",
    domainAbbreviation: "CF",
  },
  {
    areaName: "刚果(布)",
    checkKey: "^(00){0,1}(242){1}\\d{6,15}$",
    code: "242",
    domainAbbreviation: "CG",
  },
  {
    areaName: "科特迪瓦",
    checkKey: "^(00){0,1}(225){1}\\d{6,15}$",
    code: "225",
    domainAbbreviation: "CI",
  },
  {
    areaName: "库拉索岛",
    checkKey: "^(00){0,1}(599){1}\\d{6,15}$",
    code: "599",
    domainAbbreviation: "CW",
  },
  {
    areaName: "多米尼加共和国",
    checkKey: "^(00){0,1}(1809){1}\\d{6,15}$",
    code: "1809",
    domainAbbreviation: "DO",
  },
  {
    areaName: "捷克共和国",
    checkKey: "^(00){0,1}(420){1}\\d{6,15}$",
    code: "420",
    domainAbbreviation: "CZ",
  },
  {
    areaName: "阿尔及利亚",
    checkKey: "^(00){0,1}(213){1}\\d{6,15}$",
    code: "213",
    domainAbbreviation: "DZ",
  },
  {
    areaName: "厄瓜多尔",
    checkKey: "^(00){0,1}(593){1}\\d{6,15}$",
    code: "593",
    domainAbbreviation: "EC",
  },
  {
    areaName: "加纳",
    checkKey: "^(00){0,1}(233){1}\\d{6,15}$",
    code: "233",
    domainAbbreviation: "GH",
  },
  {
    areaName: "伊拉克",
    checkKey: "^(00){0,1}(964){1}\\d{6,15}$",
    code: "964",
    domainAbbreviation: "IQ",
  },
  {
    areaName: "哈萨克斯坦",
    checkKey: "^(00){0,1}(7){1}\\d{6,15}$",
    code: "7",
    domainAbbreviation: "KZ",
  },
  {
    areaName: "老挝",
    checkKey: "^(00){0,1}(856){1}\\d{6,15}$",
    code: "856",
    domainAbbreviation: "LA",
  },
  {
    areaName: "黎巴嫩",
    checkKey: "^(00){0,1}(961){1}\\d{6,15}$",
    code: "961",
    domainAbbreviation: "LB",
  },
  {
    areaName: "利比里亚",
    checkKey: "^(00){0,1}(231){1}\\d{6,15}$",
    code: "231",
    domainAbbreviation: "LR",
  },
  {
    areaName: "利比亚",
    checkKey: "^(00){0,1}(218){1}\\d{6,15}$",
    code: "218",
    domainAbbreviation: "LY",
  },
  {
    areaName: "黑山共和国",
    checkKey: "^(00){0,1}(382){1}\\d{6,15}$",
    code: "382",
    domainAbbreviation: "ME",
  },
  {
    areaName: "圣马丁",
    checkKey: "^(00){0,1}(590){1}\\d{6,15}$",
    code: "590",
    domainAbbreviation: "MF",
  },
  {
    areaName: "马其顿",
    checkKey: "^(00){0,1}(389){1}\\d{6,15}$",
    code: "389",
    domainAbbreviation: "MK",
  },
  {
    areaName: "缅甸",
    checkKey: "^(00){0,1}(95){1}\\d{6,15}$",
    code: "95",
    domainAbbreviation: "MM",
  },
  {
    areaName: "巴基斯坦",
    checkKey: "^(00){0,1}(92){1}\\d{6,15}$",
    code: "92",
    domainAbbreviation: "PK",
  },
  {
    areaName: "波多黎各",
    checkKey: "^(00){0,1}(1787){1}\\d{6,15}$",
    code: "1787",
    domainAbbreviation: "PR",
  },
  {
    areaName: "索马里",
    checkKey: "^(00){0,1}(252){1}\\d{6,15}$",
    code: "252",
    domainAbbreviation: "SO",
  },
  {
    areaName: "圣文森特和格林纳丁斯",
    checkKey: "^(00){0,1}(1784){1}\\d{6,15}$",
    code: "1784",
    domainAbbreviation: "VC",
  },
  {
    areaName: "科索沃",
    checkKey: "^(00){0,1}(383){1}\\d{6,15}$",
    code: "383",
    domainAbbreviation: "XK",
  },
  {
    areaName: "尼泊尔",
    checkKey: "^(00){0,1}(977){1}\\d{6,15}$",
    code: "977",
    domainAbbreviation: "NP",
  },
  {
    areaName: "马耳他",
    checkKey: "^(00){0,1}(356){1}\\d{6,15}$",
    code: "356",
    domainAbbreviation: "MT",
  },
  {
    areaName: "英国",
    checkKey: "^(00){0,1}(44){1}[347-9](\\d{8,9}|\\d{11,12})$",
    code: "44",
    domainAbbreviation: "GB",
  },
  // {
  //   areaName: "美国",
  //   checkKey: "^(00){0,1}(1){1}\\d{10,12}$",
  //   code: "1",
  //   domainAbbreviation: "US",
  // },
  // {
  //   areaName: "加拿大",
  //   checkKey: "^(00){0,1}(1){1}\\d{10}$",
  //   code: "1",
  //   domainAbbreviation: "CA",
  // },
];
export default globalCountryList;