import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue";
const _hoisted_1 = {
  class: "overflow-scroll height-100-percent padding-top-40"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_nav_bar = _resolveComponent("van-nav-bar");
  const _component_van_search = _resolveComponent("van-search");
  const _component_van_cell = _resolveComponent("van-cell");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createVNode(_component_van_nav_bar, {
    title: "选择国家/地区",
    "left-text": "返回",
    "left-arrow": "",
    onClickLeft: $setup.goBack
  }, null, 8, ["onClickLeft"]), _createElementVNode("section", _hoisted_1, [_createVNode(_component_van_search, {
    modelValue: $setup.areaName,
    "onUpdate:modelValue": [_cache[0] || (_cache[0] = $event => $setup.areaName = $event), $setup.onSearch],
    placeholder: "请输入国家/地区名称"
  }, null, 8, ["modelValue", "onUpdate:modelValue"]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.filterCountry, item => {
    return _openBlock(), _createBlock(_component_van_cell, {
      key: item.areaName + item.domainAbbreviation,
      title: item.areaName,
      "is-link": "",
      value: `+${item.code}`,
      onClick: $event => $setup.cellClick(item)
    }, null, 8, ["title", "value", "onClick"]);
  }), 128))])], 64);
}